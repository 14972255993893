.Board-name {
    font-family: Tahoma, sans-serif;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: -2px;
    margin-top: 0;
    text-align: center;
    clear: both;
    color: #af0a0f;
}

.FileDetail {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    color: #fff;
}

.FileDetail img {
    height: 100%;
}
