body {
    background-image: linear-gradient(#d1d5ec, #eef2ff);
    color: #000;
    font-family: arial, helvetica, sans-serif;
    font-size: 10pt;
}

hr {
    border: none;
    border-top: 1px solid #b7c5d9;
    height: 0;
}

.App {
    padding: 16px;
    min-height: 100vh;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 128px;
    fill: red;
    pointer-events: none;
}

.App-header {
    background-color: transparent;

    display: flex;
    /* flex-direction: row; */
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    margin-left: 16px;
}

.App-blinker {
    margin-left: 20px;
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
